@import "../../node_modules/breakpoint-sass/stylesheets/breakpoint";
@import "../base/variables";
@import "../utils/mixins";

// Dark navigation
body.home:not(.scrolled) {

  .site-header {
    background: #13253b;
    border-block-end: none;
  }

  .logo {
    border-right-color: palette(blue, x-dark);

    a {
      background: transparent url(/mgs_assets/images/v4/logo-slim-white.svg) 0 0 / contain no-repeat;
    }
    img {
      visibility: hidden;
    }
  }

  .nav-primary {
    > li > a,
    > li > span {
      color: #fff;
    }
  }

  .nav-buttons .button {
    background-color: transparent;
    border: 1px solid palette(blue, x-dark);
    color: palette(blue, x-light) !important;

    &:hover {
      background-color: palette(blue, xx-dark);
    }
  }
}
.logo > a,
.logo img {
  transition-duration: 0s; // avoid fadeout of color/white logo
}

.banner-home {
  position: relative;
  display: grid;
  grid-template-columns: auto minmax(300px,29em) auto;
  overflow: hidden;
}

.banner-home-content {
  padding-inline: 2em;
  background-color: rgba(19, 37, 59, 0.6);
  margin: 3rem auto;
  position: relative;
  height: 100%;
  font-size: 0.8em;

  @include breakpoint($med) {
    font-size: 1em;
  }

  h1 {
    font-family: 'Dosis', sans-serif;
    margin-block: 0 .25em;
    font-size: 3.2em;
    line-height: 1.2;
    text-wrap: balance;
    color: #fff;
  }

  .banner-intro {
    color: #65BEF6;
    text-wrap: pretty;
    font-size: 1.5em;
    font-weight: 300;
  }

  .grid {
    --grid-item-width: 45%;
    text-align: center;

    a {
      color: #65BEF6;
      text-decoration: none;
      padding: .5em;
      border-radius: .5em;
      border: 1px solid transparent;

      &:hover {
        border-color: #1a5b84;
        color: #fff;
      }
    }
    svg {
      display: block;
      margin: 0 auto .5em;
    }
  }
}

@keyframes slideupleft {
  from {
    transform: translateY(3em) translateX(-3em) scale(1);
    opacity: 0;
  }
  to {
    transform: translateY(0) translateX(0) scale(var(--scalar));
    opacity: 1;
  }
}
@keyframes slideupright {
  from {
    transform: translateY(3em) translateX(3em) scale(1);
    opacity: 0;
  }
  to {
    transform: translateY(0) translateX(0) scale(var(--scalar));
    opacity: 1;
  }
}

[class*="banner-home-illustrations"] {
  opacity: .75;
  height: 100%;
  position: relative;

  img {
    --scalar: 1;
    position: absolute;
    max-width: none;
    transform: scale(var(--scalar));
    animation: .5s ease-in-out slideupleft;
    animation-fill-mode: both;

    @include breakpoint(1440px) { --scalar: 1.2; }
    @include breakpoint(1600px) { --scalar: 1.4; }
  }

  .clipboard   { bottom: 24%;  right: calc(13vw*var(--scalar)); animation-delay: .4s; }
  .whiteboard  { bottom: 0%;   right: calc(10vw*var(--scalar)); animation-delay: .3s; }
  .report      { bottom: -14%; right: calc(12vw*var(--scalar)); animation-delay: .2s; }
  .cards       { bottom: -14%; right: calc(0vw*var(--scalar));  animation-delay: .1s;  }
  .certificate { bottom: 49%;   left: calc(10vw*var(--scalar)); animation-delay: .5s; animation-name: slideupright; }
  .calendar    { bottom: 16%;   left: calc(5vw*var(--scalar));  animation-delay: .4s; animation-name: slideupright;  }
  .filmstrip   { bottom: 2%;    left: calc(3vw*var(--scalar));  animation-delay: .3s; animation-name: slideupright;  }
  .laptop      { bottom: -4%;   left: calc(1vw*var(--scalar));  animation-delay: .2s; animation-name: slideupright;  }
  .index-cards { bottom:  2%;   left: calc(18vw*var(--scalar)); animation-delay: .1s; animation-name: slideupright; }
} 

.banner-metrics {
  padding-block: 1em;
}
.metrics-list {
  width: 100%;
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  gap: 1.5em 3em;
  margin-block: 1em;
  font-size: 0.85em;

  .count {
    display: block;
  }

  .handwriting {
    color: inherit;
    font-weight: bold;
    font-size: 3.25em;
    line-height: 1.2;
  }
}

h2 {
  font-size: .9em;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: .05em;
}
h2.bordered {
  color: palette(teal);  
}

.help-grid {
  --gap: 4em 2em;
  font-size: 0.9em;

  h4, p, .button {
    width: 92%;
    margin-inline: auto;
  }

  h4 {
    font-size: 1em;
    color: inherit;
    font-weight: bold;
    margin-block: 0.5em 0;
  }

  p {
    margin-block: 0 1em;
  }
}
.help-grid > div {
  display: flex;
  flex-direction: column;

  .button {
    margin-block-start: auto;
    white-space: normal;
  }
}

.readmore-grid {
  --gap: 0;

  > * {
    padding: 1em 0;
  }
  h2 {
    font-size: 0.85em;
  }
  a:hover {
    color: #fff;
  }
}

.readmore-blog {
  color: #fff;

  * {
    color: inherit;
  }
  .grid {
    --grid-item-width: 50%;
    --gap: 0;
    padding-inline: 1em;

    @include breakpoint($lg) {
      padding-left: 10%;
    }
  }
  img {
    aspect-ratio: 15/13; // make it just slightly wider than square
    object-fit: cover;
  }
  .stack {
    --space: .25em;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  h2 {
    opacity: .7;
  }
  h3 {
    font-family: 'Dosis', $sans-font;
    font-size: clamp(1.2em, 1.8vw, 4em);;
    line-height: 1.2;
  }
}

.readmore-search {
  background-color: palette(blue, x-dark);
  padding-inline: 2em;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .search-content {
    width: 100%;
    max-width: 36em;
    margin-inline: auto;
  }
  
  h2 {
    color: palette(blue, light);
    text-align: center;
  }

  .search-field {
    position: relative;
    height: 2.7em;
  }
  input[type=search] {
    width: 100%;
    height: 100%;
    font-size: 1.2em;
    font-family: $sans-font;
    border-radius: 1.35em;
    border: 4px solid palette(blue, light);
    box-shadow: rgba(44, 122, 202, .5) 0 0 0 5px;
    padding-inline: 1em 4em;
    transition: all .15s;

    &:focus-within {
      box-shadow: rgba(101, 190, 246, .5) 0 0 0 5px;
      border-color: rgba(170, 221, 255, 1.00);
      outline: none;
    }
  }

  button {
    background: none;
    border: none;
    height: 100%;
    position: absolute;
    right: 1em;
    width: 4em;
    padding: 0;
    cursor: pointer;

    svg {
      height: 50%;
      margin-top: 5%;
    }
  }

  .cluster {
    justify-content: space-between;
  }

  a {
    color: #fff;
    text-transform: capitalize;
  }
}