:root {
  --container-width: 1170px;
}

body {
  --header-height: 5.9rem;
  --header-border-height: 6px;
  --announcement-height: 0px;
  --page-block-start: calc(var(--header-height) + var(--announcement-height) - var(--header-border-height));
}

@media (min-width: 900px) {
  body {
    --header-height: 5rem;
  }
}
body.home:not(.scrolled) .site-header {
  background: #13253b;
  border-block-end: none;
}
body.home:not(.scrolled) .logo {
  border-right-color: #25578b;
}
body.home:not(.scrolled) .logo a {
  background: transparent url(/mgs_assets/images/v4/logo-slim-white.svg) 0 0/contain no-repeat;
}
body.home:not(.scrolled) .logo img {
  visibility: hidden;
}
body.home:not(.scrolled) .nav-primary > li > a,
body.home:not(.scrolled) .nav-primary > li > span {
  color: #fff;
}
body.home:not(.scrolled) .nav-buttons .button {
  background-color: transparent;
  border: 1px solid #25578b;
  color: #d5efff !important;
}
body.home:not(.scrolled) .nav-buttons .button:hover {
  background-color: #1d446c;
}

.logo > a,
.logo img {
  transition-duration: 0s;
}

.banner-home {
  position: relative;
  display: grid;
  grid-template-columns: auto minmax(300px, 29em) auto;
  overflow: hidden;
}

.banner-home-content {
  padding-inline: 2em;
  background-color: rgba(19, 37, 59, 0.6);
  margin: 3rem auto;
  position: relative;
  height: 100%;
  font-size: 0.8em;
}
@media (min-width: 600px) {
  .banner-home-content {
    font-size: 1em;
  }
}
.banner-home-content h1 {
  font-family: "Dosis", sans-serif;
  margin-block: 0 0.25em;
  font-size: 3.2em;
  line-height: 1.2;
  text-wrap: balance;
  color: #fff;
}
.banner-home-content .banner-intro {
  color: #65BEF6;
  text-wrap: pretty;
  font-size: 1.5em;
  font-weight: 300;
}
.banner-home-content .grid {
  --grid-item-width: 45%;
  text-align: center;
}
.banner-home-content .grid a {
  color: #65BEF6;
  text-decoration: none;
  padding: 0.5em;
  border-radius: 0.5em;
  border: 1px solid transparent;
}
.banner-home-content .grid a:hover {
  border-color: #1a5b84;
  color: #fff;
}
.banner-home-content .grid svg {
  display: block;
  margin: 0 auto 0.5em;
}

@keyframes slideupleft {
  from {
    transform: translateY(3em) translateX(-3em) scale(1);
    opacity: 0;
  }
  to {
    transform: translateY(0) translateX(0) scale(var(--scalar));
    opacity: 1;
  }
}
@keyframes slideupright {
  from {
    transform: translateY(3em) translateX(3em) scale(1);
    opacity: 0;
  }
  to {
    transform: translateY(0) translateX(0) scale(var(--scalar));
    opacity: 1;
  }
}
[class*=banner-home-illustrations] {
  opacity: 0.75;
  height: 100%;
  position: relative;
}
[class*=banner-home-illustrations] img {
  --scalar: 1;
  position: absolute;
  max-width: none;
  transform: scale(var(--scalar));
  animation: 0.5s ease-in-out slideupleft;
  animation-fill-mode: both;
}
@media (min-width: 1440px) {
  [class*=banner-home-illustrations] img {
    --scalar: 1.2;
  }
}
@media (min-width: 1600px) {
  [class*=banner-home-illustrations] img {
    --scalar: 1.4;
  }
}
[class*=banner-home-illustrations] .clipboard {
  bottom: 24%;
  right: calc(13vw * var(--scalar));
  animation-delay: 0.4s;
}
[class*=banner-home-illustrations] .whiteboard {
  bottom: 0%;
  right: calc(10vw * var(--scalar));
  animation-delay: 0.3s;
}
[class*=banner-home-illustrations] .report {
  bottom: -14%;
  right: calc(12vw * var(--scalar));
  animation-delay: 0.2s;
}
[class*=banner-home-illustrations] .cards {
  bottom: -14%;
  right: calc(0vw * var(--scalar));
  animation-delay: 0.1s;
}
[class*=banner-home-illustrations] .certificate {
  bottom: 49%;
  left: calc(10vw * var(--scalar));
  animation-delay: 0.5s;
  animation-name: slideupright;
}
[class*=banner-home-illustrations] .calendar {
  bottom: 16%;
  left: calc(5vw * var(--scalar));
  animation-delay: 0.4s;
  animation-name: slideupright;
}
[class*=banner-home-illustrations] .filmstrip {
  bottom: 2%;
  left: calc(3vw * var(--scalar));
  animation-delay: 0.3s;
  animation-name: slideupright;
}
[class*=banner-home-illustrations] .laptop {
  bottom: -4%;
  left: calc(1vw * var(--scalar));
  animation-delay: 0.2s;
  animation-name: slideupright;
}
[class*=banner-home-illustrations] .index-cards {
  bottom: 2%;
  left: calc(18vw * var(--scalar));
  animation-delay: 0.1s;
  animation-name: slideupright;
}

.banner-metrics {
  padding-block: 1em;
}

.metrics-list {
  width: 100%;
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  gap: 1.5em 3em;
  margin-block: 1em;
  font-size: 0.85em;
}
.metrics-list .count {
  display: block;
}
.metrics-list .handwriting {
  color: inherit;
  font-weight: bold;
  font-size: 3.25em;
  line-height: 1.2;
}

h2 {
  font-size: 0.9em;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.05em;
}

h2.bordered {
  color: #00c3a9;
}

.help-grid {
  --gap: 4em 2em;
  font-size: 0.9em;
}
.help-grid h4, .help-grid p, .help-grid .button {
  width: 92%;
  margin-inline: auto;
}
.help-grid h4 {
  font-size: 1em;
  color: inherit;
  font-weight: bold;
  margin-block: 0.5em 0;
}
.help-grid p {
  margin-block: 0 1em;
}

.help-grid > div {
  display: flex;
  flex-direction: column;
}
.help-grid > div .button {
  margin-block-start: auto;
  white-space: normal;
}

.readmore-grid {
  --gap: 0;
}
.readmore-grid > * {
  padding: 1em 0;
}
.readmore-grid h2 {
  font-size: 0.85em;
}
.readmore-grid a:hover {
  color: #fff;
}

.readmore-blog {
  color: #fff;
}
.readmore-blog * {
  color: inherit;
}
.readmore-blog .grid {
  --grid-item-width: 50%;
  --gap: 0;
  padding-inline: 1em;
}
@media (min-width: 900px) {
  .readmore-blog .grid {
    padding-left: 10%;
  }
}
.readmore-blog img {
  aspect-ratio: 15/13;
  object-fit: cover;
}
.readmore-blog .stack {
  --space: .25em;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.readmore-blog h2 {
  opacity: 0.7;
}
.readmore-blog h3 {
  font-family: "Dosis", "interface", sans-serif;
  font-size: clamp(1.2em, 1.8vw, 4em);
  line-height: 1.2;
}

.readmore-search {
  background-color: #25578b;
  padding-inline: 2em;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.readmore-search .search-content {
  width: 100%;
  max-width: 36em;
  margin-inline: auto;
}
.readmore-search h2 {
  color: #65bef6;
  text-align: center;
}
.readmore-search .search-field {
  position: relative;
  height: 2.7em;
}
.readmore-search input[type=search] {
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  font-family: "interface", sans-serif;
  border-radius: 1.35em;
  border: 4px solid #65bef6;
  box-shadow: rgba(44, 122, 202, 0.5) 0 0 0 5px;
  padding-inline: 1em 4em;
  transition: all 0.15s;
}
.readmore-search input[type=search]:focus-within {
  box-shadow: rgba(101, 190, 246, 0.5) 0 0 0 5px;
  border-color: rgb(170, 221, 255);
  outline: none;
}
.readmore-search button {
  background: none;
  border: none;
  height: 100%;
  position: absolute;
  right: 1em;
  width: 4em;
  padding: 0;
  cursor: pointer;
}
.readmore-search button svg {
  height: 50%;
  margin-top: 5%;
}
.readmore-search .cluster {
  justify-content: space-between;
}
.readmore-search a {
  color: #fff;
  text-transform: capitalize;
}